/* Add this at the end of your CSS file */

/* Hamburger Menu Styles */
#check {
    display: none;
}

.nav-btn {
    display: none;
}

.hamburger-menu-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 10px;
}

.hamburger-menu {
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.hamburger-menu div {
    width: 100%;
    height: 3px;
    background-color: #000;
    transition: all 0.3s ease;
}

#check:checked ~ .nav-btn {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 1000;
    padding-top: 60px;
}

.nav-btn .nav-links {
    display: flex;
    flex-direction: column;
}

.nav-link {
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #ccc;
}

/* Add more styles as needed */
.search-bar-container {
    padding-top: 20vh;
    width: 40%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
  }