.slider {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .image {
    width: 100%;
    height: auto; /* Set the height of the images */
    object-fit: cover; /* Ensure the image covers the entire area */
  }
  
  .left-arrow,
  .right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 3rem; /* Set the size of the arrow icons */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for the arrows */
    padding: 10px;
    border: none;
    cursor: pointer;
  }
  
  .left-arrow {
    left: 10px;
  }
  
  .right-arrow {
    right: 10px;
  }
  
  .slide {
    display: none; /* Hide all slides by default */
  }
  
  .slide.active {
    display: block; /* Show the active slide */
  }
  @media(max-width: 1700px){
    .left-arrow,
    .right-arrow {
      top: 53%;
    }
  }
  @media(max-width: 1440px){
    .left-arrow,
    .right-arrow {
      top: 53%;
    }
  }
  @media(max-width: 1000px){
    .left-arrow,
    .right-arrow {
      top: 53%;
    }
  }
  @media(max-width: 720px){
    .left-arrow,
    .right-arrow {
      top: 48%;
      font-size: 2.5rem;
    }
  }
  @media(max-width: 550px){
    .left-arrow,
    .right-arrow {
      top: 54%;
      font-size: 2.5rem;
    }
  }
  @media(max-width: 425px){
    .left-arrow,
    .right-arrow {
      top: 50%;
      font-size: 2.5rem;
    }
  }