@import url("//cdn.web-fonts.ge/fonts/bpg-algeti/css/bpg-algeti.min.css");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
li{
    list-style: none;
}

.ze{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .zee{
    display: block;
    align-items: center;
  }
  .zeee{
    position: absolute;
    width: 100%;
    height: 400px;
    background-color: #71797E;
    color: rgb(0, 0, 0);
  }
  
.navbar{
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: #71797E;
    /* background: #2B2B2B; */
    /* background: #3E3024; */
    min-height: 140px;
    height: auto;
}
.head{
    height: 140px;
    width: 90%;
    margin-left: 5%; 
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.divider{
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.logoo{
    color: #2F4F4F;
    font-family: 'Montserrat', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.nav{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
}
.nn{
    display: flex;
    width: 130px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
}
.nn h1 {
    color: var(--Text-main-color, #2F4F4F);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.nn h2{
    width: 24px;
    height: 24px;
}
.wrapper{
    width: 1px;
    height: 20px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 100%), #2F4F4F;
}
.svgs{
    display: flex;
    width: 172px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
}
.dividerwrapper{
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 100%), #2F4F4F;
    height: 1px;
}
.scc{
    /* background-color: #1C1B1F; */
    /* background: #2B2B2B; */
    background: transparent;
    top: 0;
}
.second{
    
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.child{
    display: flex;
    /* padding: 10px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.child h1, .sas{
    color: var(--Text-main-color, #2F4F4F);
    font-family: "BPG Algeti", sans-serif;
    font-size: 29px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

/*Responsive*/
@media(max-width: 1300px){
    .child h1{
        font-size: 20px;
    }
    .logoo{
        font-size: 30px;
    }
}
@media(max-width: 1100px){
    .child h1{
        font-size: 15px;
    }
}
@media(max-width: 900px){
    .nn h1{
        font-size: 11px;
    }
    .resp{
        width: 20px;
        height: 20px;
    }
    .svgs{
        width: 100px;
    }
    .svgs svg{
        width: 20px;
        height: 20px;
    }
} 
@media(max-width: 500px){
    /* .nn h1{
        font-size: 9px;
    }
    .resp{
        width: 15px;
        height: 15px;
    }
    .svgs svg{
        width: 15px;
        height: 15px;
    } */
}
@media(max-width: 480px){
    .logoo{
        font-size: 25px;
    }
}
@media(max-width: 380px){
    .nn{
        padding: 0px;
        width: 90px;
    }
    .svgs{
        width: 90px;
    }
    .logoo{
        font-size: 22px;
    }
}
.category{
    padding-top: 25px;
    padding-left: 45px;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
}
.flexx{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-container {
    display: flex;
    justify-content: space-between; 
    
}  
.flex-item {
    flex: 1;
}

.lii{
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    padding-top: 10.5px;
    line-height: normal;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


ul{
    list-style: none;
}

a{
    text-decoration: none;
}




.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.container{
    justify-content: space-around;
    display: flex;
    position: relative;
}

.logo-container{
    flex: 1;
    display: flex;
    align-items: center;
}

.nav-btn{
    flex: 3;
    display: flex;
}

.nav-links{
    flex: 2;
}

.log-sign{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.btn{
    display: inline-block;
    padding: .5rem 1.3rem;
    font-size: .7rem;
    border: 2px solid #fff;
    border-radius: 2rem;
    line-height: 1;
    margin: 0 .2rem;
    transition: .3s;
    text-transform: uppercase;
}

.btn.solid, .btn.transparent:hover{
    background-color: #71797E;
    color: #000000;
}

.btn.transparent, .btn.solid:hover{
    background-color: transparent;
    color: #fff;
}

.nav-links > ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.nav-link{
    position: relative;
}

.nav-link > a{
    font-family: "BPG Algeti", sans-serif;
    line-height: 3rem;
    color: #ffff;
    padding: 0 .8rem;
    letter-spacing: 1px;
    font-size: .95rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .5s;
}

.nav-link > a > i{
    margin-left: .2rem;
}

.nav-link:hover > a{
    transform: scale(1.1);
}

.dropdown{
    position: absolute;
    top: 100%;
    left: 0;
    width: 10rem;
    z-index: 1000;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.dropdown ul{
    position: relative;
}

.dropdown-link > a{
    font-family: "BPG Algeti", sans-serif;
    display: flex;
    background-color: #fff;
    color: #2F4F4F;
    padding: .5rem 1rem;
    font-size: 0.9rem;
    align-items: center;
    justify-content: space-between;
    transition: .3s;
}

.dropdown-link:hover > a{
    background-color: #2F4F4F;
    color: #fff;
}

.dropdown-link:not(:nth-last-child(2)){
    border-bottom: 1px solid #efefef;
}

.dropdown-link i{
    transform: rotate(-90deg);
}

.arrow{
    position: absolute;
    width: 11px;
    height: 11px;
    top: -5.5px;
    left: 32px;
    background-color: #fff;
    transform: rotate(45deg);
    cursor: pointer;
    transition: .3s;
    z-index: -1;
}

.dropdown-link:first-child:hover ~ .arrow{
    background-color: #2F4F4F;
}

.dropdown-link{
    position: relative;
}

.dropdown.second{
    top: 20px;
    /* left: 100%; */
    cursor: pointer;
    transform: translateX(10px);
}

.dropdown.second .arrow{
    top: 10px;
    left: -5.5px;
}

.nav-link:hover > .dropdown,
.dropdown-link:hover > .dropdown{
    transform: translate(0, 0);
    opacity: 1;
    pointer-events: auto;
}

.hamburger-menu-container{
    flex: 1;
    display: none;
    align-items: center;
    justify-content: flex-end;
}

.hamburger-menu{
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 32px;

}

.hamburger-menu div{
    width: 1.6rem;
    height: 3px;
    border-radius: 3px;
    background-color: #ffffff;
    position: relative;
    z-index: 1001;
    transition: .5s;
}

.hamburger-menu div:before,
.hamburger-menu div:after{
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: #ffffff;
    border-radius: 3px;
    transition: .5s;
}

.hamburger-menu div:before{
    transform: translateY(-7px);
}

.hamburger-menu div:after{
    transform: translateY(7px);
}

#check{
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 90000;
    cursor: pointer;
    opacity: 0;
    display: none;
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div{
    background-color: transparent;
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div:before{
    transform: translateY(0) rotate(-45deg);
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div:after{
    transform: translateY(0) rotate(45deg);
}

@keyframes animation{
    from{
        opacity: 0;
        transform: translateY(15px);
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }
}

@media (max-width: 110000000px){
    .hamburger-menu-container{
        display: flex;
    }

    #check{
        display: block;
    }

    .nav-btn{
        position: fixed;
        height: calc(100vh - 3rem);
        top: 3rem;
        left: 0;
        z-index: 1000;
        width: 100%;
        background-color: #2F4F4F !important;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        overflow-x: hidden;
        overflow-y: auto;
        transform: translateX(-100%);
        transition: .65s;
    }

    #check:checked ~ .nav-btn{
        transform: translateX(0);
        width: 80%;
        max-width: 600px;
        right: 0;
    }

    #check:checked ~ .nav-btn .nav-link,
    #check:checked ~ .nav-btn .log-sign{
        animation: animation .5s ease forwards var(--i);
    }

    .nav-links{
        flex: initial;
        width: 100%;
    }

    .nav-links > ul{
        flex-direction: column;
    }

    .nav-link{
        width: 100%;
        opacity: 0;
        transform: translateY(15px);
    }

    .nav-link > a{
        line-height: 1;
        padding: 1.6rem 2rem;
         
    }

    .nav-link:hover > a{
        transform: scale(1);
        background-color: #2F4F4F;
    }

    .dropdown, .dropdown.second{
        position: initial;
        top: initial;
        left: initial;
        transform: initial;
        opacity: 1;
        pointer-events: auto;
        width: 100%;
        padding: 0;
        background-color: #2F4F4F;
        display: none;
    }
    
    .nav-link:hover > .dropdown,
    .dropdown-link:hover > .dropdown{
        display: block;
    }

    .nav-link:hover > a > i,
    .dropdown-link:hover > a > i{
        transform: rotate(360deg);
    }

    .dropdown-link > a{
        background-color: transparent;
        color: #fff;
        padding: 1.2rem 2rem;
        line-height: 1;
    }

    .dropdown.second .dropdown-link > a{
        padding: 1.2rem 2rem 1.2rem 3rem;
    }

    .dropdown.second .dropdown.second .dropdown-link > a{
        padding: 1.2rem 2rem 1.2rem 4rem;
    }

    .dropdown-link:not(:nth-last-child(2)){
        border-bottom: none;
    }

    .arrow{
        z-index: 1;
        background-color: #2F4F4F;
        left: 10%;
        transform: scale(1.1) rotate(45deg);
        transition: .5s;
    }

    .nav-link:hover .arrow{
        background-color: #2F4F4F;
    }

    .dropdown .dropdown .arrow{
        display: none;
    }

    .dropdown-link:hover > a{
        background-color: #2F4F4F;
    }

    .dropdown-link:first-child:hover ~ .arrow{
        background-color: #2F4F4F;
    }

    .nav-link > a > i{
        font-size: 0.9rem;
        transform: rotate(-90deg);
        transition: .7s;
    }

    .dropdown i{
        font-size: 1rem;
        transition: .7s;
    }

    .log-sign{
        flex: initial;
        width: 100%;
        padding: 1.5rem 1.9rem;
        justify-content: flex-start;
        opacity: 0;
        transform: translateY(15px);
    }
}
@media(max-width: 400px){
    .nav-link a{
        font-size: 13px;
    }
}
@media(max-width: 350px){
    .nav-link a{
        font-size: 11px;
    }
}
@media(max-width: 1000px){
    .tishi{
        display: none;
    }
}