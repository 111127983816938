/* .search {
  position: relative;
  width: 80%;
  max-width: 600px;
}

.searchInputs {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 50px;
  padding: 0.5em;
  background-color: white;
}

.searchIcon {
  margin-right: 8px;
}

.search input {
  width: 100%;
  border: none;
  outline: none;
  padding: 0.5em;
}
*/
.dataResult {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  
  max-height: 200px; 
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  z-index: 1000;
}

.dataItem {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.dataItem img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.dataItem p {
  margin: 0;
} 
.searchBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  max-width: 500px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 8px;
  background: #ffffff;
  border-radius: 50px;
  position: relative;
  height: 40px;
}

.searchButton {
  color: white;
  position: absolute;
  right: 8px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #2F4F4F;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: inline-block; */
  -webkit-transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}
/*hover effect*/
button:hover {
  color: #fff;
  background-color: #1A1A1A;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px;
          box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px;
  -webkit-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
          transform: translateY(-3px);
}
/*button pressing effect*/
button:active {
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  color: rgb(0, 0, 0);
  font-size: 15px;
  padding: 8px 12px;
  height: 10px;
}

@media(max-width: 700px){
  .searchBox{
    margin-top: 7px;
    height: 35px;
  }

}